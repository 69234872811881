<template>
  <b-modal
    id="open-topografia-modal"
    hide-header
    hide-footer
    centered
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">
        Editar aparelho
      </div>
      <span class="icon-box">
        <v-close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label for="aparelho">Aparelho</label>
            <input
              id="topografia-modal"
              type="text"
              class="form-control"
              :class="{ error: validate && !aparelho }"
              v-model="name"
            />
            <div v-if="validate && !aparelho" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="wrapper-button">
          <b-button variant="primary" size="lg" @click="updateTopografia()">
            Salvar e voltar
          </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'

export default {
  name: 'TopografiaModal',
  props: {
    data: Object,
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      name: null,
      validate: false
    }
  },
  components: {
    'v-close': Close,
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('open-topografia-modal')
      this.$emit('onHide')
    },

    async updateTopografia() {
      if(!this.name){
        this.$toast.error('Campo não pode ser vazio')
        return
      }
      try {
        const response = await this.api.updateTopografia(this.data.id, {
          name: this.name,
        })
        this.$toast.success('Aparelho para Topografia alterado com sucesso!')
        this.$emit('setTopografia', response.data)
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } 
    },

  },

  watch: {
    data() {
      this.name = this.data?.name
    }
  }
}
</script>

<style lang="scss" scoped>
#open-topografia-modal {
  .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0 !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

  .around-content {
    textarea {
      min-height: 200px;
      max-height: 1000px;
    }
  }

  .wrapper-button {
    width: 100%;
    display: flex !important;
    justify-content: end !important;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
